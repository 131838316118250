<template>
    <v-card>
        <v-data-table
            :headers="headers"
            :items="purchase_detail"
            disable-pagination
            hide-default-footer
            class="w-table"
            height="45vh"
        >
            <template v-slot:top>
                <div>
                    <template>
                        <v-row>
                            <v-col cols="10">
                                <span class="ml-4 subtitle-1 font-weight-bold w-title-table">
                                    Productos
                                </span>
                            </v-col>
                            <v-col cols="2">                                
                                <v-btn dark color="success"
                                    @click="handleShowAddProduct">
                                    Agregar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>                    
                </div>
                <v-divider></v-divider>
            </template>

            <template v-slot:item.price="{item}">
                <span class="item-price">{{ item.price | currency('S/') }}</span>
            </template>

            <template v-slot:item.igv="{item}">
                <v-switch value :input-value="item.subject_igv"
                    hide-details 
                    class="mt-0" 
                    @change="handleChangeIncludeIgv(item)">
                </v-switch>
            </template>

            <template v-slot:item.due_date="{item}">
                <span v-if="item.due_date">{{ item.due_date | moment('DD/MM/YYYY') }}</span>
            </template>

            <template v-slot:item.actions="{item}">                

                <v-icon small @click="handleEditItem(item)" color="amber">
                    fas fa-pen
                </v-icon>

                <v-icon small @click="handleDeleteItem(item)" color="red" class="ml-3">
                    fas fa-minus-circle
                </v-icon>
            </template>
        </v-data-table>

        <add-product ref="addProduct"></add-product>
    </v-card>    
</template>

<script>

import {mapState, mapActions} from 'vuex'

import AddProduct from './AddProduct';
import ArrayTools from '../../helpers/ArrayTools';

export default {
    name: 'Products',
    components: {
        AddProduct
    },
    data() {
        return {            
            headers: [
                { text: 'PRODUCTO', value: 'product_name', sortable: false },
                { text: 'CANTIDAD', value: 'quantity', width: 100, sortable: false },
                { text: 'P. UNITARIO', value: 'price', width: 120, sortable: false },
                { text: 'INC. IGV', value: 'igv', width: 90, sortable: false },
                { text: 'F. VENC.', value: 'due_date', width: 105, sortable: false },
                { text: '', value: 'actions', sortable: false }
            ],
        }
    },
    computed: {
        ...mapState('purchases', [
            'storehouse_id',
            'purchase_detail',
            'purchase_id'
        ])
    },
    methods: {
        ...mapActions('purchases', [
            'setPurchaseDetail',
            'replaceItemPurchaseDetail',
            'removeItemPurchaseDetail'
        ]),
        handleShowAddProduct() {
            this.$refs.addProduct.showForm('Agregar producto', 'add')
        },
        handleEditItem(item) {
            this.$refs.addProduct.showForm('Editar producto', 'edit', item)
        },
        handleDeleteItem(item) {
            let indexFounded = ArrayTools.getIndexByProductIdInOrder(this.purchase_detail, item.product_id);
            if (indexFounded > -1) {
                this.removeItemPurchaseDetail(indexFounded);
            }
        },
        handleChangeIncludeIgv(item){            
            let product = Object.assign({}, item);
            product.subject_igv = !product.subject_igv
            product.subtotal = parseFloat(product.quantity) * parseFloat(product.price)
            product.total = parseFloat(product.subtotal) - parseFloat(product.discount)
            let igv = 0
            if (product.subject_igv) {
                let real_total = product.total / 1.18
                real_total = Math.round(real_total * 100) / 100                
                igv = product.total - real_total
                product.total = real_total                                
            } else {                
                igv = product.total * 0.18
            }
            product.igv = Math.round(igv * 100) / 100;

            let item_purchase = Object.assign({}, product);
            let indexFounded = ArrayTools.getIndexByProductIdInOrder(this.purchase_detail, item_purchase.product_id);
            if (indexFounded > -1) {
                this.replaceItemPurchaseDetail({
                    index: indexFounded,
                    item: item_purchase
                });
            }            
        }
    },
    created () {
        if (this.purchase_id == null) {
            this.setPurchaseDetail([]);
        }        
    }
}
</script>

<style scoped>
.item-price{
    color: #4caf50;
}
</style>