<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="600"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>{{ titleForm }}</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-card outlined height="75vh">
                            <v-card-text>
                                <template>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="productForm.category_id"
                                                :items="categories"
                                                label="Seleccione categoría"
                                                outlined
                                                :item-text="customText"
                                                item-value="id"
                                                hide-details
                                                @change="handleSelectCategory"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="productForm.brand_id"
                                                :items="brands"
                                                label="Seleccione marca"
                                                outlined
                                                :item-text="customText"
                                                item-value="id"
                                                clearable
                                                hide-details
                                                @change="handleSelectBrand"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                ref="product_id"
                                                v-model="productForm.product_id"
                                                :items="products"
                                                label="Seleccione producto"
                                                outlined
                                                :item-text="customText"
                                                item-value="id"
                                                clearable
                                                @change="handleSelectProduct"
                                                :rules="[() => !!productForm.product_id || 'Es necesario seleccionar un producto']"
                                                hide-details="auto"
                                                required
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field v-model="productForm.quantity"
                                                ref="quantity"
                                                label="Cantidad" 
                                                type="number"
                                                outlined
                                                @input="handleInputQuantity"
                                                :rules="[
                                                    () => !!productForm.quantity || 'Es necesario ingresar la cantidad',
                                                    () => (!!productForm.quantity && parseFloat(productForm.quantity) > 0) || 'La cantidad debe ser mayor a cero'
                                                ]"
                                                hide-details="auto"
                                                required>
                                            </v-text-field>
                                        </v-col>

                                        <v-col cols="6">
                                            <v-text-field v-model="productForm.price"
                                                ref="price" 
                                                label="Precio Unitario" 
                                                type="number" 
                                                outlined
                                                @input="handleInputPrice"
                                                :rules="[
                                                    () => !!productForm.price || 'Es necesario ingresar el precio unitario',
                                                    () => (!!productForm.price && parseFloat(productForm.price) > 0) || 'El precio debe ser mayor a cero'
                                                ]"
                                                hide-details="auto"
                                                required>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="6">
                                            <v-menu
                                                v-model="menuDueDate"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="productForm.due_date"
                                                        label="Fecha de vencimiento"
                                                        readonly
                                                        clearable
                                                        v-on="on"
                                                        outlined
                                                        hide-details                         
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker v-model="productForm.due_date" 
                                                    @input="menuDueDate = false" 
                                                    no-title
                                                    locale="es">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="productForm.subtotal" 
                                                label="Subtotal" 
                                                type="number"
                                                outlined 
                                                hide-details
                                                disabled>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="6">
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="productForm.discount" 
                                                label="Descuento" 
                                                type="number" 
                                                outlined 
                                                hide-details
                                                @input="handleInputDiscount">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

            </template>                    
        </div>

        <div class="mt-1 pl-3 pt-0 pr-3">
            <template>
                <v-row>
                    <v-col cols="6" class="pb-1">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6" class="pb-1">
                        <v-btn dark color="success" block height="40" @click="handleAddProduct">
                            <v-icon class="mr-1" size="16">fas fa-plus-circle</v-icon>
                            <span v-if="action == 'add'">Agregar</span>
                            <span v-else>Actualizar</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </template>               
            
        </div>

    </v-navigation-drawer>
</template>

<script>

import { mapState, mapActions} from 'vuex'
import ArrayTools from '../../helpers/ArrayTools'

export default {
    name: 'AddProduct',
    data() {
        return {
            drawer: false,
            titleForm: null,
            action: null,
            menuDueDate: false,
            productForm: {
                id: null,
                category_id: null,
                category_name: null,
                brand_id: null,
                brand_name: null,
                product_id: null,
                product_name: null,
                quantity: null,
                price: null,
                discount: null,
                subtotal: 0,
                subject_igv: true,
                igv: 0,
                total: null,
                due_date: null
            }
        }
    },
    computed: {
        ...mapState('categories', [
            'categories'
        ]),
        ...mapState('brands', [
            'brands'
        ]),
        ...mapState('units', [
            'units'
        ]),
        ...mapState('products', [
            'products'
        ]),
        ...mapState('purchases', [
            'storehouse_id',
            'purchase_detail'
        ])
    },
    methods: {
        ...mapActions('categories', [
            'getCategories',
        ]),
        ...mapActions('brands', [
            'getBrands',
        ]),
        ...mapActions('units', [
            'getUnits',
        ]),
        ...mapActions('products', [
            'getProducts',
        ]),
        ...mapActions('purchases', [
            'addItemPurchaseDetail',
            'replaceItemPurchaseDetail'
        ]),
        async showForm(title, action, product = null) {
            await this.clearData()
            this.titleForm = title
            this.action = action
            this.filterProducts();
            if (product) {
                this.setProduct(product)
            }
            this.drawer = !this.drawer
        },
        clearData() {
            this.titleForm = null
            this.action = null
            this.productForm.id = null
            this.productForm.category_id = null
            this.productForm.category_name = null
            this.productForm.brand_id = null
            this.productForm.brand_name = null
            this.productForm.product_id = null
            this.productForm.product_name = null
            this.productForm.quantity = null
            this.productForm.price = null
            this.productForm.discount = null
            this.productForm.subtotal = 0
            this.productForm.subject_igv = true
            this.productForm.igv = 0
            this.productForm.total = null;
            this.productForm.due_date = null;
            this.clearErrors();
        },
        setProduct(product) {
            this.productForm.id = product.id
            this.productForm.category_id = product.category_id
            this.productForm.category_name = product.category_name
            this.productForm.brand_id = product.brand_id
            this.productForm.brand_name = product.brand_name
            this.productForm.product_name = product.product_name
            this.productForm.product_id = product.product_id
            this.productForm.quantity = product.quantity
            this.productForm.price = product.price
            this.productForm.discount = product.discount
            this.productForm.subtotal = product.subtotal
            this.productForm.subject_igv = product.subject_igv
            this.productForm.igv = product.igv
            this.productForm.total = product.total
            this.productForm.due_date = product.due_date;

            this.handleSelectCategory(this.productForm.category_id);
        },
        closeForm() {
            this.clearData()
            this.drawer = false
        },
        handleCancel() {
            this.closeForm()
        },
        customText(item){
            let text = item.name
            
            return text
        },
        handleSelectCategory(value) {            
            if (value !== undefined && value !== null) {
                this.productForm.category_id = value;
                let categoryIndex = ArrayTools.getIndexById(this.categories, value);
                this.productForm.category_name = this.categories[categoryIndex].name;
            } else {
                this.productForm.category_id = null;
                this.productForm.category_name = null;
            }
            this.filterProducts();  
        },
        handleSelectBrand(value) {
            if(value !== undefined && value !== null){
                this.productForm.brand_id = value;
                let brandIndex = ArrayTools.getIndexById(this.brands, value);
                this.productForm.brand_name = this.categories[brandIndex].name;
            } else {
                this.productForm.brand_id = null;
                this.productForm.brand_name = null;
            }
            this.filterProducts();
        },
        handleInputQuantity() {
            this.calculateSubtotal()
        },
        handleInputPrice() {
            this.calculateSubtotal()
        },
        handleInputDiscount() {
            this.calculateTotal()
        },       
        calculateSubtotal() {
            let quantity = 0
            let price = 0
            if (this.productForm.quantity) {
                quantity = parseInt(this.productForm.quantity)
            }
            if (this.productForm.price) {
                price = parseFloat(this.productForm.price)
            }
            let subtotal = quantity * price
            this.productForm.subtotal = subtotal

            this.calculateTotal()
        },
        calculateTotal() {
            let total = parseFloat(this.productForm.subject_igv) - parseFloat(this.productForm.discount)
            this.productForm.total = total
        },
        handleAddProduct() {            
            if (!this.existErrors()) {
                this.createProductItem();
            }
        },
        createProductItem() {
            if (this.action == 'add') {
                let today = new Date()
                this.productForm.id = today.getTime()
            }

            if (this.productForm.discount === null || this.productForm.discount === undefined) {
                this.productForm.discount = 0
            }
            
            this.productForm.discount = parseFloat(this.productForm.discount)
            this.productForm.quantity = parseInt(this.productForm.quantity)
            this.productForm.price = parseFloat(this.productForm.price)
            this.productForm.subtotal = parseFloat(this.productForm.quantity) * parseFloat(this.productForm.price)
            this.productForm.total = parseFloat(this.productForm.subtotal) - this.productForm.discount
            //calculo de igv
            let igv = 0
            if (this.productForm.subject_igv) {
                let real_total = this.productForm.total / 1.18
                real_total = Math.round(real_total * 100) / 100
                igv = this.productForm.total - real_total
                this.productForm.total = real_total                                
            } else {                
                igv = this.productForm.total * 0.18
            }
            this.productForm.igv = Math.round(igv * 100) / 100

            let item_purchase = Object.assign({}, this.productForm);     
            
            let indexFounded = ArrayTools.getIndexByProductIdInOrder(this.purchase_detail, item_purchase.product_id);
            if (this.action == 'add') {
                if (indexFounded > -1) {
                    this.$swal({
                        text: "El producto ya ha sido agregado.",
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Aceptar"
                    });
                } else {
                    this.addItemPurchaseDetail(item_purchase);
                }
            } else if (this.action == 'edit') {
                this.replaceItemPurchaseDetail({
                    index: indexFounded,
                    item: item_purchase
                });
            }

            this.closeForm()
        },
        setProductDescription(value) {
            if (this.productForm.description && this.productForm.description.length > 0) {
                this.productForm.description = this.productForm.description + ' ' + value
            } else {
                this.productForm.description = value
            }
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.productForm).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.productForm[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.productForm).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
        filterProducts () {
            let filters = {
                storehouse_id: this.storehouse_id,
                category_id: this.productForm.category_id,
                brand_id: this.productForm.brand_id
            }
            this.getProducts(filters);
        },
        handleSelectProduct (value) {
            if (value !== undefined && value !== null) {
                this.productForm.product_id = value;
                let productIndex = ArrayTools.getIndexById(this.products, value);
                this.productForm.product_name = this.products[productIndex].name;
            } else {
                this.productForm.product_id = null;
                this.productForm.product_name = null;
            }
        }
    }
}
</script>

<style scoped>

</style>