<template>
    <v-card >
        <v-card-text>
            <template>
                <v-row>
                    <v-col cols="4">
                        <v-autocomplete
                            ref="supplier_id"
                            :items="suppliers"
                            label="Seleccione proveedor"
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                            :value="supplier_id"
                            @change="set_supplier_id"
                            :rules="[() => !!supplier_id || 'Es necesario seleccionar un proveedor']"
                            hide-details="auto"
                            required
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                        <v-menu
                            v-model="menu1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                ref="register_date"
                                :value="register_date"
                                label="Fecha"
                                readonly
                                clearable
                                v-on="on"
                                outlined
                                :rules="[() => !!register_date || 'Es necesario ingresar una fecha']"
                                hide-details="auto"
                                required
                                ></v-text-field>
                            </template>
                            <v-date-picker :value="register_date" 
                                @input="menu1 = false" 
                                no-title 
                                @change="set_register_date"
                                locale="es">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <!--
                    <v-col cols="4">
                        <v-autocomplete
                            ref="storehouse_id"
                            :value="storehouse_id"
                            :items="storehouses"
                            label="Almacén"
                            outlined
                            :item-text="customText"
                            item-value="id"
                            @change="set_storehouse_id"
                            :rules="[() => !!storehouse_id || 'Es necesario seleccionar almacén']"
                            hide-details="auto"
                            required
                        ></v-autocomplete>
                    </v-col>
                    -->

                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-switch 
                            :value="credit_purchase" 
                            class="mt-0" 
                            label="COMPRA AL CRÉDITO"
                            hide-details
                            :false-value="false"
                            :true-value="true"
                            @change="handleChangeCreditPurchase"
                        ></v-switch>
                    </v-col>
                    <v-col cols="4" v-if="credit_purchase == true">
                        <v-menu
                            v-model="menuDatePayment"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    :value="date_payment"
                                    label="Fecha de pago"
                                    readonly
                                    clearable
                                    outlined
                                    dense
                                    v-on="on"
                                    hide-details
                                    ></v-text-field>
                            </template>
                            <v-date-picker :value="date_payment" 
                                @input="menuDatePayment = false" 
                                no-title
                                locale="es"
                                :min="date_payment_min"
                                @change="set_date_payment">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </template>
        </v-card-text>        
    </v-card>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'

export default {
    name: 'Header',
    data() {
        return {
            menu1: false,
            menuDatePayment: false,
            date_payment_min: new Date().toISOString().substr(0, 10),
        }
    },
    computed: {
        ...mapState('suppliers', [
            'suppliers'
        ]),
        ...mapState('purchases', [
            'supplier_id',
            'register_date',
            'sale_order',
            'credit_purchase',
            'date_payment',
            'storehouse_id'
        ]),
        ...mapState('storehouses', [
            'storehouses'
        ])
    },
    methods: {
        ...mapMutations('purchases', [
            'set_supplier_id',
            'set_register_date',
            'set_sale_order',
            'set_credit_purchase',
            'set_date_payment',
            'set_storehouse_id'
        ]),
        ...mapActions('suppliers', [
            'getSuppliers'
        ]),
        ...mapActions('purchases', [
            'clean_data'
        ]),
        ...mapActions('storehouses', [
            'getStorehouses'
        ]),
        customText(item){
            let text = item.name
            
            return text
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.sedeForm).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.sedeForm[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.sedeForm).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
        handleChangeCreditPurchase (value) {
            this.set_credit_purchase(value);
            if (this.credit_purchase == false) {
                this.set_date_payment(null);
            }
        }
    },
    mounted() {
        this.getSuppliers();
        this.getStorehouses();
    }
}
</script>

<style scoped>

</style>