<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :href="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="9" class="pt-1">
                    <span v-if="purchase_id"
                        class="headline font-weight-medium">
                        Modificar Compra
                    </span>
                    <span v-else class="headline font-weight-medium">Nueva Compra</span>
                </v-col>
                <v-col cols="3" class="pt-1">
                    <v-btn v-if="purchase_id"
                        dark 
                        color="#023145" 
                        width="140" 
                        class="float-right ml-3" 
                        @click="handleUpdatePurchase">
                        Actualizar
                    </v-btn>
                    <v-btn v-else
                        dark 
                        color="#023145" 
                        width="140" 
                        class="float-right ml-3" 
                        @click="handleStorePurchase">
                        Guardar
                    </v-btn>
                    <v-btn dark color="red" class="float-right" @click="handleCancel">
                        Cancelar
                    </v-btn>                    
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="8" class="pt-0">
                    <template>
                        <v-row>
                            <v-col cols="12" class="pt-0">
                                <Header ref="headerPurchase"></Header>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <Products></Products>
                            </v-col>
                        </v-row>
                    </template>
                </v-col>

                <v-col cols="4" class="pt-0">
                    <template>
                        <v-row class="h-100">
                            <v-col cols="12" class="pt-0">
                                <Invoice ref="invoicePurchase"></Invoice>
                            </v-col>
                        </v-row>
                    </template>
                    
                </v-col>
            </v-row>
        </template>

        <v-snackbar
            v-model="show_message_products"            
            color="error"
            :timeout="3000"
            :top="'top'"
        >
            {{ message_error }}
            <v-btn
            dark
            text
            @click="show_message_products = false"
            >
                Cerrar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import OfficeLogin from '../../../components/auth/OfficeLogin';
import Header from '../../../components/purchases/Header'
import Products from '../../../components/purchases/Products'
import Invoice from '../../../components/purchases/Invoice'

export default {
    name: 'Purchases',
    components: {
        Header,
        Products,
        Invoice,
        OfficeLogin
    },
    data() {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Logística',
                    disabled: true,
                    href: '',
                }
            ],
            show_message_products: false,
            message_error: null
        }
    },

    computed: {
        ...mapState('purchases', [
            'purchase_detail',
            'credit_purchase',
            'date_payment',
            'purchase_id'
        ])
    },
    methods: {
        ...mapMutations('purchases', [
            'set_register_date'
        ]),
        ...mapActions('purchases', [
            'storePurchase',
            'clean_data',
            'setPurchaseDetail',
            'updatePurchase'
        ]),
        ...mapActions('categories', [
            'getCategories',
        ]),
        ...mapActions('brands', [
            'getBrands',
        ]),
        ...mapActions('units', [
            'getUnits',
        ]),
        ...mapActions('products', [
            'getProducts',
        ]),
        async handleStorePurchase(){
            if (!this.existErrors()) {
                if (this.hasProducts() && this.hasDatePayment()) {
                    await this.storePurchase();
                    this.clearErrors();
                    this.setPurchaseDetail([]);
                }
            }
        },
        async handleUpdatePurchase () {
            if (!this.existErrors()) {
                if (this.hasProducts() && this.hasDatePayment()) {
                    await this.updatePurchase();
                    this.clearErrors();
                    this.setPurchaseDetail([]);
                }
            }
        },
        handleCancel() {
            this.clean_data();
            this.setPurchaseDetail([]);
            this.clearErrors();
        },
        existErrors(){
            let hasErrors = false;

            //Verificar datos de header
            let headerPurchase = this.$refs.headerPurchase;

            if (!headerPurchase.$refs.supplier_id.value) hasErrors = true;
            headerPurchase.$refs.supplier_id.validate(true);

            if (!headerPurchase.$refs.register_date.value) hasErrors = true;
            headerPurchase.$refs.register_date.validate(true);

            //if (!headerPurchase.$refs.storehouse_id.value) hasErrors = true;
            //headerPurchase.$refs.storehouse_id.validate(true);

            //Verificar datos de factura
            let invoicePurchase = this.$refs.invoicePurchase;

            if (!invoicePurchase.$refs.type_document.value) hasErrors = true;
            invoicePurchase.$refs.type_document.validate(true);

            if (!invoicePurchase.$refs.serie.value) hasErrors = true;
            invoicePurchase.$refs.serie.validate(true);

            if (!invoicePurchase.$refs.correlative.value) hasErrors = true;
            invoicePurchase.$refs.correlative.validate(true);

            return hasErrors;
        },
        hasProducts(){
            let quantity = this.purchase_detail.length;

            let has = true;
            if (quantity <= 0) {
                has = false;
                this.show_message_products = true;
                this.message_error = 'Debe ingresar los productos comprados.';
            }

            
            return has;
        },
        hasDatePayment () {
            let has = true;
            if (this.credit_purchase == true) {
                if (this.date_payment == null) {
                    has = false;
                    this.show_message_products = true;
                    this.message_error = 'Debe ingresar fecha de pago.';
                }
            }
            return has;
        },
        clearErrors() {
            let headerPurchase = this.$refs.headerPurchase;

            headerPurchase.$refs.supplier_id.reset();
            headerPurchase.$refs.register_date.reset();
            //headerPurchase.$refs.storehouse_id.reset();

            //Verificar datos de factura
            let invoicePurchase = this.$refs.invoicePurchase;

            invoicePurchase.$refs.type_document.reset();
            invoicePurchase.$refs.serie.reset();
            invoicePurchase.$refs.correlative.reset();
        },
        loadData () {
            this.getCategories();
            this.getBrands();
            this.getUnits();
            this.getProducts();
            if (this.purchase_id == null) {
                this.clean_data();
            }
        }
    },
    created () {
        this.loadData();
    },
    mounted () {
        if (this.purchase_id == null) {
            this.clean_data();
            this.clearErrors();
        }        
    }
}
</script>

<style scoped>

</style>