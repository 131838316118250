<template>
    <v-card class="card-invoice" style="height: 100% !important">
        <v-card-title>
            <v-combobox
                ref="type_document"
                :value="type_document"
                :items="type_documents"
                outlined
                label="Comprobante"
                @change="set_type_document"
                :rules="[() => !!type_document || 'Debe seleccionar comprobante']"
                hide-details="auto"
                required
            ></v-combobox>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pl-0 pr-0 pt-0 pb-0">
            <div class="pl-3 pr-3 pt-3 pb-3">
                <template>                    
                    <v-row>
                        <v-col cols="5">
                            <v-text-field 
                                ref="serie"
                                :value="serie" 
                                label="Serie" 
                                outlined
                                @input="set_serie"
                                :rules="[() => !!serie || 'Es necesario seleccionar la serie']"
                                hide-details="auto"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols=7>
                            <v-text-field 
                                ref="correlative"
                                :value="correlative" 
                                label="Número" 
                                outlined 
                                @input="set_correlative"
                                :rules="[() => !!correlative || 'Es necesario seleccionar el número de factura']"
                                hide-details="auto"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </template>
            </div>
            <hr class="divider-invoice">
            <div class="list-items-invoice">
                <template>
                    <v-list two-line class="pt-0">
                        <v-list-item v-for="item in purchase_detail" :key="'i-'+item.id" class="invoice-item">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.product_name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ item.quantity }}
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <span>{{ item.subtotal | currency('S/') }}</span>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider></v-divider>
                    </v-list>
                </template>
            </div>
            
            <v-list>
                <v-list-item class="invoice-summary">
                    <v-list-item-content class="pt-1 pb-1">
                        <v-list-item-title>
                            <span class="invoice-discount">Descuento</span>
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="mt-1 mb-1">
                        <span class="invoice-discount">{{ discount | currency('S/') }}</span>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item class="invoice-summary">
                    <v-list-item-content class="pt-1 pb-1">
                        <v-list-item-title>
                            Subtotal
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="mt-1 mb-1">
                        <span>{{ subtotal | currency('S/') }}</span>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item class="invoice-summary">
                    <v-list-item-content class="pt-1 pb-1">
                        <v-list-item-title>
                            IGV(18%)
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="mt-1 mb-1">
                        <span>{{ igv | currency('S/') }}</span>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item class="invoice-summary">
                    <v-list-item-content class="pt-1 pb-1">
                        <v-list-item-title>
                            Total
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="mt-1 mb-1">
                        <span>{{ total | currency('S/') }}</span>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>

import {mapState, mapActions, mapMutations} from 'vuex'

export default {
    name: 'Invoice',
    data () {
        return {
            type_documents: ['BOLETA', 'FACTURA']
        }
    },
    computed: {
        ...mapState('purchases', [
            'purchase_detail',
            'type_document',
            'serie',
            'correlative',
            'subtotal',
            'discount',
            'igv',
            'total'
        ])
    },
    methods: {
        ...mapMutations('purchases', [
            'set_type_document',
            'set_serie',
            'set_correlative'
        ]),
        ...mapActions('purchases', [
            'run_set_invoice_detail',
            'clean_data'
        ])
    }
}
</script>

<style scoped>
.card-invoice{
    height: 81vh;
    overflow: auto;
}

.title-invoice{    
    font-weight: bold;
    font-size: 1rem;
    font-family: Roboto,sans-serif !important;
}

.invoice-item{
    border-bottom: 1px solid #e0e0e0;
    text-transform: uppercase !important;
}

.divider-invoice{
    border-top: 1px dashed #303197;
}

.invoice-discount{
    color: #F44336;
}

.invoice-summary{
    min-height: 30px !important;
}
.list-items-invoice{
    height: 40vh;
    overflow-y: auto;
}

</style>